import React from 'react'
import { Nav } from '@catamaran/components'

export const Layout = ({ children }) => {
  return (
    <div>
      <Nav />
      <div className="slide-container">{children}</div>
    </div>
  )
}
