import React from 'react'
import { get } from 'lodash'

export const QuizTitle = ({ data }) => {
  return (
    <div className="flex flex-col justify-center items-center bg-lightTeal py-8 border-darkTeal border-t-8 border-b-8 w-full">
      {get(data, `title`) && (
        <span className="text-white text-3xl md:text-5xl font-bold px-16 text-center">
          {data.title}
        </span>
      )}
    </div>
  )
}
