import React, { useState, useRef, useEffect } from 'react'
import { get, uniqueId } from 'lodash'
import SignatureCanvas from 'react-signature-canvas'
import { SubmitButton } from '@catamaran/components'

export function Confirmation({ data, onSubmit, submitting, error }) {
  const [checked, setChecked] = useState(false)
  const [hasSig, setHasSig] = useState(false)
  const [sigData, setSigData] = useState(null)
  const sigEl = useRef(null)

  useEffect(() => {
    if (sigEl && sigData) {
      sigEl.current.fromData(sigData)
    }
  })

  return (
    <div className="text-gray-800 px-16 py-4 max-w-screen-xl">
      <div className="flex flex-col">
        {get(data, 'primary.slide_title.0.text') && (
          <span className="text-2xl md:text-3xl font-bold text-darkTeal">
            {get(data, 'primary.slide_title.0.text')}
          </span>
        )}
      </div>
      <div className="my-4" key={uniqueId()}>
        {get(data, 'description') && (
          <span className="font-semibold text-lg leading-10">
            {get(data, 'primary.description')}
          </span>
        )}
        {get(data, 'primary.checkbox_label') && (
          <div className="flex flex-col md:flex-row items-center">
            <div className="flex items-center mr-4 mb-6">
              <div className="flex items-center h-5">
                <input
                  id="comments"
                  type="checkbox"
                  checked={checked}
                  onChange={(e) => {}}
                  onClick={(e) => {
                    setChecked(!checked)
                  }}
                  className="form-checkbox cursor-pointer h-4 w-4 text-lightTeal transition duration-150 ease-in-out"
                />
              </div>
              <div className="ml-3 text-sm leading-5">
                <label htmlFor="comments" className="font-medium cursor-pointer text-gray-700">
                  {get(data, 'primary.checkbox_label')}
                </label>
              </div>
            </div>
          </div>
        )}
        <SignatureCanvas
          penColor="black"
          backgroundColor="white"
          clearOnResize={false}
          onEnd={() => {
            if (sigEl) {
              setSigData(sigEl.current.toData())
            }

            setHasSig(true)
          }}
          canvasProps={{ width: 320, height: 150, className: 'sigCanvas' }}
          ref={sigEl}
        />
        <div className="mb-6" />
        <SubmitButton disabled={!checked || !hasSig || submitting} loading={submitting} text="Submit" onClick={() => {
          if (sigEl) {
            onSubmit({
              acknowledgement: get(data, 'primary.checkbox_label'),
              attachment: sigEl.current.getCanvas().toDataURL(),
            })
          }
        }} />
        {error && <span className="text-red-600">{error}</span>}
      </div>
    </div>
  )
}
