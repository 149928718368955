import React from 'react'
import { RichText as RichTextBase } from 'prismic-reactjs'
import { get, uniqueId, isEmpty } from 'lodash'

const Content = ({ children, bulletLevel }) => {
  return (
    <div
      className={`text-gray-800 text-md md:text-lg leading-8${
        bulletLevel === 'Level 2' ? ' bullet_level_2' : ''
      }`}>
      {children}
    </div>
  )
}

const RichText = ({ text, bulletLevel }) => (
  // @ts-ignore
  <RichTextBase bulletLevel={bulletLevel} render={text} Component={Content} />
)

const Image = ({ item, className = '' }) => (
  <img
    className={className}
    src={item.image.url}
    width={item.image.dimensions.width}
    height={item.image.dimensions.height}
  />
)

export const TitleWithContent = ({ data }) => {
  return (
    <div className="text-gray-800 px-16 py-4 max-w-screen-xl">
      <div className="flex flex-col">
        {get(data, 'primary.slide_title.0.text') && (
          <span className="text-2xl md:text-3xl font-bold text-darkTeal">
            {get(data, 'primary.slide_title.0.text')}
          </span>
        )}
        {get(data, 'primary.slide_subtitle.0.text') && (
          <span className="text-xl mt-2 md:text-2xl font-semibold text-gray-600 leading-10">
            {get(data, 'primary.slide_subtitle.0.text')}
          </span>
        )}
      </div>
      {get(data, 'items', []).map((item) => (
        <div className="my-4" key={uniqueId()}>
          {get(item, 'headline.0.text') && (
            <span className="font-semibold text-lg leading-10">{get(item, 'headline.0.text')}</span>
          )}
          {!isEmpty(item.image) && item.image_position === 'Top' && <Image className="mt-4 mx-auto" item={item} />}
          <div className="flex flex-col md:flex-row items-center">
            {!isEmpty(item.image) && item.image_position === 'Left' && <Image className="mr-4" item={item} />}
            {item.paragraph && <RichText text={item.paragraph} bulletLevel={item.bullet_level} />}
            {!isEmpty(item.image) && item.image_position === 'Right' && <Image className="ml-4" item={item} />}
          </div>
          {!isEmpty(item.image) && item.image_position === 'Bottom' && <Image className="mt-4 mx-auto" item={item} />}
        </div>
      ))}
    </div>
  )
}
