import React, { useState, useContext } from 'react'
import { motion } from 'framer-motion'
import { get, isNil } from 'lodash'
import { useRouter } from 'next/router'
import { Menu } from '@styled-icons/boxicons-regular/Menu'
import { UserContext, UserContextType, USER_REDUCERS } from '@catamaran/utils'
import * as ls from 'local-storage'

const variants = {
  open: { opacity: 1, height: 'auto', marginTop: 12 },
  closed: { opacity: 0, height: 0, marginTop: 0 },
}

type NavProps = {}

export const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [notificationsOpen, setNotificationsOpen] = useState(false)
  const router = useRouter()
  const path = router.pathname

  // @ts-ignore
  const [state, dispatch] = useContext(UserContext)
  const context: UserContextType = state
  const user = context.user

  const highlightedMenu =
    'mx-2 px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700'
  const regularMenu =
    'mx-2 px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700'

  return (
    <nav className="bg-gray-800 fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <a href="https://www.catamaransolutions.com" target="_blank">
                <img src="/logo-wide.svg" className="w-40 mb-1" />
              </a>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline"></div>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              <div className="ml-3 relative">
                <div>
                  {!isNil(ls.get('accessToken')) && (
                    <button
                      className="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none"
                      id="user-menu"
                      aria-label="User menu"
                      onClick={() => {
                        setMenuOpen(!menuOpen)
                        setNotificationsOpen(false)
                      }}
                      onBlur={() => setTimeout(() => setMenuOpen(false), 100)}
                      aria-haspopup="true">
                      <Menu size={20} />
                    </button>
                  )}
                </div>
                <motion.div
                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
                  style={{ overflow: 'hidden', height: 0 }}
                  animate={menuOpen ? 'open' : 'closed'}
                  variants={variants}>
                  <div
                    className="py-1 rounded-md bg-white shadow-xs"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu">
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem">
                      {`${get(user, 'first_name', '')} ${get(user, 'last_name', '')}`}
                    </a>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        dispatch({
                          type: USER_REDUCERS.logout,
                        })
                        setMenuOpen(false)
                      }}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem">
                      Sign out
                    </a>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            {!isNil(ls.get('accessToken')) && (
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
                onClick={() => {
                  setMenuOpen(!menuOpen)
                  setNotificationsOpen(false)
                }}
                onBlur={() => setTimeout(() => setMenuOpen(false), 100)}>
                <svg
                  className="block h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className="hidden h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <motion.div
          style={{ overflow: 'hidden', height: 0 }}
          animate={menuOpen ? 'open' : 'closed'}
          variants={variants}>
          <div className="pt-4 pb-3 border-t border-gray-700">
            <div className="flex items-center px-5">
              <div className="">
                <div className="text-base font-medium leading-none text-white">{`${get(
                  user,
                  'first_name',
                  '',
                )} ${get(user, 'last_name', '')}`}</div>
              </div>
            </div>
            <div className="mt-3 px-2">
              <a
                href="#"
                className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                onClick={(e) => {
                  e.preventDefault()
                  dispatch({
                    type: USER_REDUCERS.logout,
                  })
                  setMenuOpen(false)
                }}>
                Sign out
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    </nav>
  )
}
