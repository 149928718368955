import { Loading } from '@catamaran/components'

export const Login = ({ loading = false, error = null }) => (
  <div className="m-auto">
    <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white">
      <div className="px-6 py-4">
        <div className="flex flex-col items-center mb-6 mt-4">
          <img src="/logo.svg" className="w-40 mb-4" />
          <div className="font-medium text-l mb-8 text-gray-800">Orientation</div>
          <div className="flex flex-col">
            {loading && <Loading />}
            {error && (
              <div className="flex flex-col space-y-3">
                <div className="mt-6 text-sm text-red-600">{error}</div>
                <button
                  onClick={() => (window.location.href = '/')}
                  className="rounded-md bg-green-300 hover:bg-green-500 w-full py-1">
                  Reload
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)

/*
          <p className="text-gray-700 text-center text-base mb-8">
            Login to web time entry using your Catamaran Google account.
          </p>
          */
