import React, { useEffect } from 'react'
import { useState } from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { motion, AnimatePresence } from 'framer-motion'
import { get, isEmpty } from 'lodash'
import { LeftArrow } from '@styled-icons/boxicons-solid/LeftArrow'
import { RightArrow } from '@styled-icons/boxicons-solid/RightArrow'
import { VolumeFull } from '@styled-icons/boxicons-regular/VolumeFull'
import { VolumeMute } from '@styled-icons/boxicons-regular/VolumeMute'
import {
  Completed,
  Confirmation,
  Loading,
  Login,
  QuizQuestion,
  QuizTitle,
  Title,
  TitleSlide,
  TitleWithContent,
  VideoPlayer,
} from '@catamaran/components'
import { AudioPlayer } from '@catamaran/utils'

const TOPICS = gql`
  query topics {
    topics {
      uid
      title
      subtitle
      order
      voiceOver
      quiz {
        uid
        title
        body {
          slices
        }
        responses {
          question
          answer
          correct
        }
      }
      body {
        slices
      }
    }
    progress {
      progress
      complete
    }
  }
`

const UPDATE_PROGRESS = gql`
  mutation updateProgress($progress: String!) {
    updateProgress(progress: $progress) {
      success
      error
    }
  }
`

const COMPLETE_ORIENTATION = gql`
  mutation updateCompletion($complete: Boolean!) {
    updateCompletion(complete: $complete) {
      success
      error
    }
  }
`

const ANSWER_QUESTION = gql`
  mutation answerQuestion($quizUID: String!, $question: Int!, $answer: Int!) {
    answerQuestion(input: { quiz_uid: $quizUID, question: $question, answer: $answer }) {
      correct
      error
    }
  }
`

const SUBMIT_ACKNOWLEDGEMENT = gql`
  mutation submitAcknowledgement($acknowledgement: String!, $attachment: String) {
    submitAcknowledgement(input: { acknowledgement: $acknowledgement, attachment: $attachment }) {
      success
      error
    }
  }
`

const KEYS = {
  ARROW_RIGHT: 'ArrowRight',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  T: 't',
  S: 's',
}

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const RenderSlice = (slice, paginate = null) => {
  if (!slice) {
    paginate(1)
    return null
  }

  if (slice.slice_type === 'full_screen_video') {
    return <VideoPlayer url={get(slice, `primary.video.embed_url`)} />
  }

  if (slice.slice_type === 'title_w__content') {
    return <TitleWithContent data={slice} />
  }

  if (slice.slice_type === 'title') {
    return <Title data={slice} />
  }

  paginate(1)
  return null
}

export function Slideshow({ audioPlayer }: { audioPlayer: AudioPlayer }) {
  const { data, loading, error, refetch } = useQuery(TOPICS)
  const [updateProgress] = useMutation(UPDATE_PROGRESS)
  const [updateCompletion] = useMutation(COMPLETE_ORIENTATION)
  const [answerQuestion] = useMutation(ANSWER_QUESTION)
  const [acknowledgementMutation] = useMutation(SUBMIT_ACKNOWLEDGEMENT)
  const [initialLoad, setInitialLoad] = useState(false)
  const [admin, setAdmin] = useState(false)
  const [keyPresses, setKeyPresses] = useState([])
  const [quizSubmitting, setQuizSubmitting] = useState(false)
  const [acknowledgementSubmitting, setAcknowledgementSubmitting] = useState(false)
  const [wrongAnswer, setWrongAnswer] = useState(false)
  const [[topic, slide, direction], setTopic] = useState([
    0,
    get(data, 'topics[0].title') ? 0 : 1,
    0,
  ])
  const [quiz, setQuiz] = useState(null)
  const [acknowledgement, setAcknowledgement] = useState(false)
  const [acknowledgementError, setAcknowledgementError] = useState(null)
  const [acknowledgementSuccess, setAcknowledgementSuccess] = useState(false)
  const [question, setQuestion] = useState(0)
  const [completed, setCompleted] = useState(false)
  const [shouldComplete, setShouldComplete] = useState(false)
  const [muted, setMuted] = useState(false)
  const [audioState, setAudioState] = useState({
    ready: false,
    loading: false,
    playing: false,
    ended: false,
    duration: 0,
  })
  const [percentComplete, setPercentComplete] = useState(0)
  const voiceUrl =
    !completed && slide === 0 && !quiz
      ? get(data, `topics.${topic}.voiceOver`)
      : !completed && quiz && question !== 0
      ? get(quiz, `body.slices[${question - 1}].primary.voice_over.url`)
      : !completed
      ? get(data, `topics.${topic}.body.slices.${slide - 1}.primary.slide_voice_over.url`)
      : completed
      ? 'https://catamaran.s3.amazonaws.com/Completed.mp3'
      : 'https://catamaran.s3.amazonaws.com/Blank.mp3'

  const { ready, loading: audioLoading, playing, ended, duration } = audioState

  audioPlayer.stateListener = (state) => {
    setAudioState(state)
  }

  useEffect(() => {
    let timeout: number
    if (playing) {
      timeout = window.setInterval(() => {
        const pos = audioPlayer.getPosition()
        setPercentComplete((pos / duration) * 100 || 0)
      }, 500)
    } else if (ended) {
      setPercentComplete(100)
    } else {
      setPercentComplete(0)
    }
    return () => clearTimeout(timeout)
  }, [playing, ended])

  useEffect(() => {
    if (!initialLoad && !isEmpty(data)) {
      setInitialLoad(true)

      if (get(data, 'progress.progress') && !isEmpty(data.progress.progress)) {
        const progress = data.progress.progress.split('-')
        setTopic([parseInt(progress[0]), parseInt(progress[1]), 1])
      }

      if (get(data, 'progress.complete')) {
        setCompleted(data.progress.complete)
      }
    }
  }, [data])

  useEffect(() => {
    // if (ready && !audioLoading && !playing && !ended && voiceUrl) {
    //   play()
    // }
    audioPlayer.playUrl(voiceUrl)
  }, [voiceUrl])

  useEffect(() => {
    if (muted) {
      audioPlayer.setVolume(0)
      // volume(0)
    } else {
      audioPlayer.setVolume(1)
      // volume(1)
    }
  }, [muted])

  const performProgressUpdate = ({ newTopic, newSlide }) => {
    updateProgress({
      variables: {
        progress: `${newTopic}-${newSlide}`,
      },
    })
      .then((result) => {
        // console.log('Updated Progress: ', result)
      })
      .catch((e) => {
        console.log('Error Updating Progress: ', e)
      })
  }

  const paginate = (newDirection: number) => {
    audioPlayer.stop()
    // stop()

    if (completed || acknowledgement) {
      return
    }

    if (quiz && question === 0 && newDirection == 1) {
      setQuestion(question + 1)
      return
    } else if (quiz && question === 0 && newDirection == -1) {
      setQuiz(null)
      setTopic([topic - 1, 0, newDirection])
      return
    }

    const slices = data.topics[topic].body.slices

    const newTopic =
      // @ts-ignore
      parseInt(slide) + parseInt(newDirection) >= 0 &&
      // @ts-ignore
      parseInt(slide) + parseInt(newDirection) <= slices.length
        ? // @ts-ignore
          parseInt(topic)
        : // @ts-ignore
          parseInt(topic) + parseInt(newDirection)
    const newSlide =
      // @ts-ignore
      parseInt(slide) + parseInt(newDirection) < 0 ||
      // @ts-ignore
      parseInt(slide) + parseInt(newDirection) > slices.length
        ? 0
        : // @ts-ignore
          parseInt(slide) + parseInt(newDirection)

    if (newTopic !== topic && newDirection > 0 && data.topics[topic].quiz) {
      setQuiz(data.topics[topic].quiz)
    }

    if (newTopic >= 0 && newTopic < data.topics.length) {
      setTopic([
        newTopic,
        newSlide === 0 ? (data.topics[newTopic].title ? 0 : 1) : newSlide,
        newDirection,
      ])

      if (newTopic !== topic && newDirection > 0 && data.topics[topic].quiz) {
        // Nothing
      } else {
        performProgressUpdate({
          newTopic,
          newSlide: newSlide === 0 ? (data.topics[newTopic].title ? 0 : 1) : newSlide,
        })
      }
    } else if (newTopic === data.topics.length) {
      setShouldComplete(true)
    }
  }

  const nextQuestion = () => {
    setWrongAnswer(false)
    if (question + 1 > quiz.body.slices.length) {
      setQuiz(null)
      setQuestion(0)

      performProgressUpdate({
        newTopic: topic,
        newSlide: slide,
      })

      refetch()

      if (shouldComplete) {
        setCompleted(true)
        updateCompletion({ variables: { complete: true } })
      }
    } else {
      setQuestion(question + 1)
    }
  }

  const onAnswerQuestion = async ({ uid, question: qnum, answer }) => {
    setQuizSubmitting(true)
    setWrongAnswer(false)

    try {
      let response = null
      let correct = null

      if (
        get(quiz, `responses.${question - 1}`) &&
        get(quiz, `responses.${question - 1}.answer`) === answer &&
        get(quiz, `responses.${question - 1}.correct`, false)
      ) {
        response = get(quiz, `responses.${question - 1}`)
        correct = response.correct || false
      } else {
        response = await answerQuestion({ variables: { quizUID: uid, question: qnum, answer } })
        correct = get(response, 'data.answerQuestion.correct', false)
      }

      setQuizSubmitting(false)

      if (!correct) {
        setWrongAnswer(true)
      } else {
        nextQuestion()
      }
    } catch (e) {
      setQuizSubmitting(false)
      console.error(e)
    }
  }

  const onSubmitAcknowledgement = async ({ acknowledgement, attachment }) => {
    setAcknowledgementSubmitting(true)

    try {
      const response = await acknowledgementMutation({ variables: { acknowledgement, attachment } })
      const success = get(response, 'data.submitAcknowledgement.success', false)
      const error = get(response, 'data.submitAcknowledgement.error')
      setAcknowledgementSubmitting(false)

      if (success) {
        setAcknowledgement(false)
        setAcknowledgementSuccess(true)
      } else {
        setAcknowledgementError(error)
      }
    } catch (e) {
      setAcknowledgementSubmitting(false)
      setAcknowledgementError(e)
    }
  }

  useEffect(() => {
    if (!acknowledgement && acknowledgementSuccess) {
      paginate(1)
      setAcknowledgementSuccess(false)
    }
  }, [acknowledgement, acknowledgementSuccess])

  const lookForAdmin = (key) => {
    const newKeyPresses = [...keyPresses, key].slice(-5)
    setKeyPresses(newKeyPresses)

    if (newKeyPresses.join('') === 'admin') {
      setAdmin(true)
    }
  }

  const onKeyPress = (e) => {
    lookForAdmin(e.key)

    if ((quiz && question !== 0) || completed) {
      if (e.key === KEYS.S && quiz && admin) {
        nextQuestion()
      } else {
        return
      }
    }

    if (e.key === KEYS.ARROW_LEFT) {
      paginate(-1)
    } else if (
      e.key === KEYS.ARROW_RIGHT &&
      !acknowledgement &&
      (ended ||
        percentComplete > 30 ||
        (ready && !audioLoading && !playing && !ended) ||
        admin ||
        !ready ||
        !voiceUrl)
    ) {
      paginate(1)
    } else if (e.key === KEYS.T && admin) {
      if (topic + 1 < data.topics.length) {
        stop()
        setTopic([topic + 1, data.topics[topic + 1].title ? 0 : 1, 1])
        performProgressUpdate({
          newTopic: topic + 1,
          newSlide: data.topics[topic + 1].title ? 0 : 1,
        })
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', onKeyPress)

    return () => {
      window.removeEventListener('keyup', onKeyPress)
    }
  })

  useEffect(() => {
    if (
      !completed &&
      slide !== 0 &&
      !quiz &&
      get(data, `topics.${topic}.body.slices.${slide - 1}.slice_type`) === 'confirmation'
    ) {
      setAcknowledgement(true)
    } else {
      setAcknowledgement(false)
    }
  }, [topic, slide, direction])

  const bg = get(data, `topics.${topic}.body.slices.${slide - 1}.primary.background_image.url`)

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Login error={error} />
  }

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <div className="absolute w-full bg-lightTeal z-40" style={{ height: 4, top: 64 }}>
          <div
            className="h-full bg-darkTeal"
            style={{ width: ended ? '100%' : `${percentComplete}%`, transition: 'width 250ms' }}
          />
        </div>
        <motion.div
          key={`${quiz ? `quiz-${question}-` : completed ? 'completed-' : null}${topic}-${slide}`}
          className="absolute w-full max-h-full overflow-y-scroll h-full flex py-10"
          style={{ background: bg ? `url(${bg})` : null }}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 200 },
            opacity: { duration: 0.2 },
          }}>
          <div className="mute text-darkTeal hover:text-lightTeal" onClick={() => setMuted(!muted)}>
            {!muted && <VolumeFull style={{ marginLeft: 2 }} size={20} />}
            {muted && <VolumeMute style={{ marginLeft: 2 }} size={20} />}
          </div>
          <div
            className={`flex flex-col items-center w-full m-auto${
              get(data, `topics.${topic}.body.slices.${slide - 1}.slice_type`) !==
              'full_screen_video'
                ? ' py-8'
                : ''
            }`}>
            {!completed && quiz && question === 0 && <QuizTitle data={quiz} />}
            {!completed && quiz && question !== 0 && (
              <QuizQuestion
                question={quiz.body.slices[question - 1]}
                response={get(quiz, `responses.${question - 1}`)}
                submitting={quizSubmitting}
                wrongAnswer={wrongAnswer}
                onSubmit={(answer) =>
                  onAnswerQuestion({ uid: quiz.uid, question: question - 1, answer })
                }
              />
            )}
            {!completed && slide === 0 && !quiz && <TitleSlide data={data.topics[topic]} />}
            {!completed &&
              slide !== 0 &&
              !quiz &&
              get(data, `topics.${topic}.body.slices.${slide - 1}.slice_type`) !== 'confirmation' &&
              RenderSlice(get(data, `topics.${topic}.body.slices.${slide - 1}`), paginate)}
            {!completed &&
              slide !== 0 &&
              !quiz &&
              get(data, `topics.${topic}.body.slices.${slide - 1}.slice_type`) ===
                'confirmation' && (
                <Confirmation
                  data={get(data, `topics.${topic}.body.slices.${slide - 1}`)}
                  onSubmit={onSubmitAcknowledgement}
                  submitting={acknowledgementSubmitting}
                  error={acknowledgementError}
                />
              )}
            {completed && <Completed admin={admin} />}
          </div>
        </motion.div>
      </AnimatePresence>
      {(!quiz || (quiz && question === 0)) &&
        !completed &&
        !acknowledgement &&
        (ended ||
          percentComplete > 30 ||
          (ready && !audioLoading && !playing && !ended) ||
          admin ||
          !ready ||
          !voiceUrl) && (
          <div className="next text-darkTeal hover:text-lightTeal" onClick={() => paginate(1)}>
            <RightArrow style={{ marginLeft: 2 }} size={20} />
          </div>
        )}
      {(topic !== 0 || slide > 1) &&
        (!quiz || (quiz && question === 0)) &&
        !completed &&
        !acknowledgement && (
          <div className="prev text-darkTeal hover:text-lightTeal" onClick={() => paginate(-1)}>
            <LeftArrow style={{ marginLeft: -2 }} size={20} />
          </div>
        )}
    </>
  )
}
