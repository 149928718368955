import React from 'react'
import { Loading } from '@catamaran/components'

export const SubmitButton = ({
  text,
  className = '',
  onClick = () => {},
  showCheck = false,
  loading = false,
  disabled = false,
}) => {
  return (
    <span className={`shadow-sm rounded-md ${className}`}>
      <button
        type="button"
        disabled={disabled}
        onClick={onClick}
        className={`inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-darkTeal hover:bg-lightTeal focus:outline-none focus:shadow-outline-black active:bg-darkTeal transition duration-150 ease-in-out${disabled ? ' opacity-50 cursor-not-allowed' : ''}`}>
        {showCheck && (
          <svg className="-ml-1 mr-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {loading && (
          <div>
            <Loading light />
          </div>
        )}
        {!loading && text}
      </button>
    </span>
  )
}
