import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { SubmitButton } from '@catamaran/components'

const RESTART = gql`
  mutation restartOrientation {
    restartOrientation {
      success
      error
    }
  }
`

export const Completed = ({ admin }) => {
  const [loading, setLoading] = useState(false)
  const [restart] = useMutation(RESTART)

  return (
    <>
      <span className="text-gray-800 text-3xl md:text-5xl font-bold px-16 text-center">
        You have completed this orientation!
      </span>
      {admin && (
        <SubmitButton
          className="mt-4"
          text="Restart"
          loading={loading}
          onClick={async () => {
            try {
              setLoading(true)
              await restart()
              location.reload()
            } catch (e) {
              setLoading(false)
              console.error(e)
            }
          }}
        />
      )}
    </>
  )
}
