import React, { useState, useEffect } from 'react'
import { get, isNil } from 'lodash'
import { SubmitButton } from '@catamaran/components'

export const QuizQuestion = ({ question, onSubmit, submitting, wrongAnswer, response = null }) => {
  const [answer, setAnswer] = useState(null)
  const [wrong, setWrong] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!isNil(get(response, 'correct')) && answer === get(response, 'answer')) {
      setWrong(response.correct === false)
    } else {
      setWrong(wrongAnswer)
    }
  }, [wrongAnswer])

  if (!isNil(get(response, 'answer')) && !loaded) {
    setLoaded(true)
    setAnswer(response.answer)
    setWrong(response.correct === false)
  } else if (!loaded) {
    setLoaded(true)
  }

  return (
    <div className="p-16 max-w-screen-xl">
      <span className="text-darkTeal text-xl md:text-3xl font-bold">
        {get(question, 'primary.question.0.text')}
      </span>
      <div style={{ height: 40 }} />
      {get(question, 'items', []).map((choice, idx) => (
        <div key={idx} className="flex items-center mr-4 mb-4">
          <input
            id={`question-${idx}`}
            type="radio"
            name="radio"
            className="hidden"
            checked={answer === idx}
            onChange={(e) => {}}
            onClick={(e) => {
              setWrong(false)
              setAnswer(idx)
            }}
          />
          <label
            htmlFor={`question-${idx}`}
            className={`flex items-center cursor-pointer${
              wrong && idx === answer ? ' wrong' : ' text-gray-800'
            } text-lg font-semibold`}>
            <span className="w-4 h-4 inline-block mr-4 rounded-full border border-gray-800"></span>
            <div className="flex-1">{get(choice, 'answer_choice')}</div>
          </label>
        </div>
      ))}
      <div style={{ height: 40 }} />
      <SubmitButton
        disabled={answer === null}
        loading={submitting}
        text={
          !isNil(get(response, 'answer')) &&
          get(response, 'answer') === answer &&
          get(response, 'correct', false)
            ? 'Next'
            : 'Submit'
        }
        onClick={() => onSubmit(answer)}
      />
    </div>
  )
}
