import React, { FunctionComponent } from 'react'
import ReactPlayer from 'react-player'

const Container = ({ children }) => (
  <div className="relative w-full h-full" style={{ paddingTop: '56.25%' }}>
    {children}
  </div>
)

interface VideoPlayerProps {
  url: string
}

export const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({ url }: VideoPlayerProps) => {
  return (
    <Container>
      <ReactPlayer
        url={url}
        controls
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        config={{
          youtube: {
            playerVars: { modestbranding: 1 },
          },
        }}
      />
    </Container>
  )
}
