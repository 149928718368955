import React from 'react'
import { get } from 'lodash'

export const Title = ({ data }) => {
  return (
    <div className="text-gray-800 px-16 py-4 max-w-screen-xl">
      <div className="flex flex-col">
        {get(data, 'primary.full_title.0.text') && (
          <span className="text-2xl md:text-3xl font-bold text-darkTeal">
            {get(data, 'primary.full_title.0.text')}
          </span>
        )}
      </div>
    </div>
  )
}
